import React, { useEffect } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { HealthcareHero } from "../assets/backgrounds";
import CustomLink from "../components/CustomLink";
import { headerTheme } from "../partials/Header";

const BookMeetingWithUlrik = ({ location }) => {
  useEffect(() => {
    // Load the HubSpot Meetings Embed script
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.async = true;
    document.body.appendChild(script);

    // Clean up function
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Layout location={location} hideFooter>
      <section className="h-screen">

      <div
        className="meetings-iframe-container absolute w-full top-1/2 -translate-y-[40%] overflow-hidden"
        data-src="https://meetings-eu1.hubspot.com/ulrik-hansen?embed=true"
      />
      </section>
    </Layout>
  );
};

export default BookMeetingWithUlrik;

export const Head = () => (
  <SEO
    title="Meet with Ulrik Hansen | Encord"
    description="Develop, deploy, and evaluate predictive and generative vision applications at scale with high-quality training data. | Encord"
  />
);